import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

// Utils
import { encodeForm } from "../utils/encode-form";

const Form = styled.form`
  display: ${props => props.display};

  width: 100%;
  max-width: 450px;

  label,
  span {
    width: 100%;
    display: block;
    font-size: 14px;
  }

  textarea {
    // width: calc(450px - 2px - 14px);
    width: calc(100% - 15px);
    border: 1px solid #0a2a2f;
    background-color: transparent;

    margin: 10px 0 0 0;
    padding: 7px;

    &:focus {
      outline: 0;
    }
  }

  input {
    color: #0a2a2f;
    background-color: transparent;

    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #0a2a2f;

    padding: 0;

    width: 100%;
    max-width: 450px;
  }

  input[type="file"] {
    border: 0;
    font-size: 14px;
  }

  input[type="checkbox"] {
    width: auto;
  }

  input:focus {
    outline: 0;
  }

  button {
    background-color: transparent;
    padding: 5px 10px;
    border: 1px solid #0a2a2f;
    color: #0a2a2f;

    transition: 300ms all ease;

    &:hover {
      background-color: #0a2a2f;
      color: #fff;
    }
  }

  .gdpr-text {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    & > div {
      & > p {
        font-size: 14px;
        line-height: 18px;

        margin: 0;
        padding: 0 0 0 10px;

        & a {
          text-decoration: underline;
        }
      }
    }
  }
`;

const Message = styled.p`
  display: ${props => props.display};
`;

export const GeneralEnquiry = ({ gdprText, display }) => {
  const [formMessage, setFormMessage] = useState(null);

  // Form Setup
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      name: "",
      telephone: "",
      email: "",
      message: "",
      gdprCheckbox: false,
    },
  });

  const onSubmit = (data, e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodeForm({
        "form-name": "general-enquiry-form",
        ...data,
      }),
    })
      .then(() => {
        setFormMessage(
          "Thank you for submitting your details. We will be in touch soon."
        );
        e.target.reset();
      })
      .catch(error => {
        console.log("form error:", error);
        setFormMessage(
          "There was an issue submitting your form. Please contact us at hello@sparkopportunities.com"
        );
      });
  };

  return (
    <>
      <Form
        name="general-enquiry-form"
        method="post"
        data-netlify="true"
        onSubmit={handleSubmit(onSubmit)}
        netlify-honeypot="bot-field"
        display={display}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="general-enquiry-form" />
        <p>
          <label htmlFor="name">Name</label>
          {/* errors will return when field validation fails  */}
          {errors.name && <span>This field is required</span>}
          <input
            name="name"
            type="text"
            autoComplete="name"
            ref={register({ required: true, maxLength: 80 })}
          />
        </p>
        <p>
          <label htmlFor="telephone">Phone</label>
          {/* errors will return when field validation fails  */}
          {errors.telephone && <span>This field is required</span>}
          <input
            name="telephone"
            type="tel"
            autoComplete="telephone"
            ref={register}
          />
        </p>
        <p>
          <label htmlFor="email">Email</label>
          {/* errors will return when field validation fails  */}
          {errors.email && <span>This field is required</span>}
          <input
            name="email"
            type="email"
            autoComplete="email"
            ref={register({
              required: true,
            })}
          />
        </p>
        <p>
          <label htmlFor="message">Message</label>
          {/* errors will return when field validation fails  */}
          {errors.message && <span>This field is required</span>}
          <textarea
            name="message"
            rows="10"
            ref={register({ required: true })}
          />
        </p>
        <div className="gdpr-text">
          {/* errors will return when field validation fails  */}
          {errors.gdprCheckbox && <span>This field is required</span>}
          <div>
            <input
              type="checkbox"
              id="general-enquiry-form-gdpr"
              name="general-enquiry-form-gdpr"
              value={true}
              checked={true}
              defaultChecked={true}
              ref={register({ required: true })}
            />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: gdprText.html,
            }}
          />
        </div>
        <p>
          <button type="submit">Submit</button>
        </p>
      </Form>

      {formMessage !== null && (
        <Message display={display}>{formMessage}</Message>
      )}
    </>
  );
};
