import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  height: 55px;

  @media (max-width: 500px) {
    height: 30px;
  }
`;

const AdviceText = () => (
  <Icon
    id="advice-text"
    dataName="advice-text"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1256.87 240.28"
  >
    <g>
      <path
        d="M26.63,185,81.4,36.39H106L160.37,185H136.6l-12.74-36.3H63.14L50.4,185Zm42.88-54.14h48l-24-67.72Z"
        fill="#0a2a2f"
      />
      <path
        d="M212.39,187.55q-15.28,0-27-7.22a50.09,50.09,0,0,1-18.26-19.74,60.22,60.22,0,0,1-6.58-28.45q0-15.92,6.58-28.34a49.32,49.32,0,0,1,18.36-19.53q11.78-7.11,27.07-7.11a47.24,47.24,0,0,1,22,4.88,36.1,36.1,0,0,1,14.75,13.8V32.15h22.5V185H251.66l-2.34-16.35a48.3,48.3,0,0,1-14,13.16Q226.4,187.55,212.39,187.55Zm4-19.53q14.43,0,23.67-10t9.23-25.69q0-15.92-9.23-25.79t-23.67-9.87q-14.44,0-23.78,9.87t-9.34,25.79a39,39,0,0,0,4.25,18.47,31.66,31.66,0,0,0,11.78,12.63A32.31,32.31,0,0,0,216.42,168Z"
        fill="#0a2a2f"
      />
      <path
        d="M321.29,185,281.81,79.7h23.56l29.3,83.86L364,79.7h23.35L348,185Z"
        fill="#0a2a2f"
      />
      <path
        d="M407.91,59.74a14.65,14.65,0,0,1-10.51-3.93,13,13,0,0,1-4.14-9.87,13,13,0,0,1,4.14-9.87,16,16,0,0,1,21,0,13,13,0,0,1,4.14,9.87,13,13,0,0,1-4.14,9.87A14.65,14.65,0,0,1,407.91,59.74ZM396.66,185V79.7h22.5V185Z"
        fill="#0a2a2f"
      />
      <path
        d="M488.15,187.55a54.64,54.64,0,0,1-27.71-7,49.89,49.89,0,0,1-19.11-19.43q-6.9-12.42-6.9-28.77t6.9-28.77a49.89,49.89,0,0,1,19.11-19.42,54.64,54.64,0,0,1,27.71-7q19.53,0,32.8,10.19T538,114.94H514.48a22.17,22.17,0,0,0-9.34-13.59,31.16,31.16,0,0,0-32.27-.74,30.07,30.07,0,0,0-11.25,12.1q-4.25,8-4.25,19.64T461.62,152a30.83,30.83,0,0,0,11.25,12.21,28.92,28.92,0,0,0,15.29,4.25,29.57,29.57,0,0,0,17-4.88,22.24,22.24,0,0,0,9.34-13.8H538a45.59,45.59,0,0,1-17,27.39Q507.68,187.55,488.15,187.55Z"
        fill="#0a2a2f"
      />
      <path
        d="M601.09,187.55q-15.5,0-27.49-6.9a49,49,0,0,1-18.79-19.21Q548,149.12,548,132.77q0-16.56,6.69-29.08a48.68,48.68,0,0,1,18.68-19.53q12-7,27.92-7,15.5,0,27,6.9a48.8,48.8,0,0,1,17.83,18.36,51.14,51.14,0,0,1,6.37,25.26q0,2.12-.11,4.56t-.32,5.41H570.1q1.06,15.28,10.08,23.25a30.64,30.64,0,0,0,20.91,8q9.55,0,16-4.35a26.35,26.35,0,0,0,9.66-11.78h22.5a47.79,47.79,0,0,1-16.88,24.84Q619.77,187.55,601.09,187.55Zm0-91.92a31.86,31.86,0,0,0-20,6.69q-8.71,6.69-10.61,20.06H630q-.64-12.31-8.7-19.53T601.09,95.62Z"
        fill="#0a2a2f"
      />
    </g>
  </Icon>
);

export default AdviceText;
