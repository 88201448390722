import React from "react";
import styled from "styled-components";
import { TextLoop } from "react-text-loop-next";
import ReactFullpage from "@fullpage/react-fullpage";
import Div100vh from "react-div-100vh";

// Components
import { Collaboration } from "./collaboration";
import { Jobs } from "./jobs";
import { Section } from "./section";
import { Articles } from "./articles";
import { Testimonials } from "./testimonials";
import { Contact } from "./contact";

// Functions
//https://gist.github.com/thevangelist/8ff91bac947018c9f3bfaad6487fa149
const convertToKebabCase = string => {
  return string.replace(/\s+/g, "-").toLowerCase();
};

const AnimationContainer = styled.div`
  width: 100%;
  height: 120px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 500px) {
    height: 180px;
    align-self: center;

    padding: 0 0 60px 0;
  }

  @media (max-width: 400px) {
    height: 100px;
  }
`;

const IntroRow = styled.div`
  height: calc(100vh - 60px);

  padding: 30px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  & .flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  @media (max-width: 767px) {
    height: 100%;
    padding: 20px;
  }
`;

const SparkText = styled.div`
  margin: 0;

  // opacity: ${props => (props.visible ? 1 : 0)};
  // transition: 500ms opacity ease;

  position: relative;

  & img {
    width: 30vw;

    @media (max-width: 500px) {
      width: 45vw;
    }
  }
`;

const AnimatedText = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: 500ms opacity ease;

  & > div > div {
    width: 60vw !important;

    @media (max-width: 500px) {
      width: 91vw;
    }
  }

  & object {
    width: 60vw;

    @media (max-width: 500px) {
      width: 91vw;
    }
  }
`;

const AnimationTextContainer = styled.div`
  width: 100%;
  max-width: 510px;
  color: ${props => (props.color ? props.color : "#fff")};

  padding-top: 100px;

  transition: 500ms opacity ease;

  & h1 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0;

    opacity: ${props => (props.opportunityVisible ? 1 : 0)};
    transition: 500ms opacity ease;

    @media (max-width: 900px) {
      font-size: 26px;
    }
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }

  & p {
    font-size: 20px;

    line-height: 1.1;
    letter-spacing: 0.3px;

    @media (max-width: 900px) {
      font-size: 18px;
    }

    @media (max-width: 500px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media (max-width: 500px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: flex-end;
    align-items: flex-end;

    padding: 0;

    & > div {
      width: 100%;

      align-self: flex-end;
    }
  }
`;

const AnimationTextBox = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: 500ms opacity ease;

  @media (max-width: 500px) {
    max-width: 100%;
    padding-top: 0;

    align-self: flex-end;

    & br {
      display: none;
    }
  }
`;

const SocialMediaLinks = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: 500ms opacity ease;

  margin: 30px 0 0 0;

  & .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > div {
      margin: 0 20px 0 0;

      & img {
        width: 36px;
        height: 36px;
        object-fit: contain;
      }
    }
  }
`;

const FullpageWrapper = ({
  isSparkVisible,
  sparkIcon,
  isAnimationVisible,
  isIntroTextVisible,
  isOpportunitiesTextVisible,
  content,
  currentSlide,
  setCurrentSlide,
  setFullPageInstance,
  fullPageInstance,
}) => {
  const afterLoad = (origin, destination, direction) => {
    setCurrentSlide(destination.index);
  };

  const socialLinks = content.social_links_homepage.map((link, index) => (
    <div key={`social_media_link_${index}`}>
      <a href={link.link.url} target="_blank" rel="noreferrer">
        <img src={link.icon.url} alt="social media icon" />
      </a>
    </div>
  ));

  const animations = content.animation_order.map(animation => (
    <div key={animation.animation_text.document.prismicId}>
      <object
        type="image/svg+xml"
        data={animation.animation_text.document.data.svg_text.url}
        alt={`Spark Icon`}
      />
    </div>
  ));

  const sectionColors = Array.from(
    content.sections.map(section => {
      if (
        section.page.document.type === "jobs" ||
        section.page.document.type === "collaboration"
      ) {
        return "#0a2a2f";
      } else {
        return section.page.document.data.color;
      }
    })
  );

  const textColors = Array.from(
    content.sections.map(section => {
      if (section.page.document.type === "jobs") {
        return "#0a2a2f";
      } else {
        return section.page.document.data.color;
      }
    })
  );

  const sections = content.sections.map((section, index) => {
    if (section.page.document.type === "section") {
      return (
        <Section
          data={section.page.document}
          title={section.page.document.data.title.text}
          currentSlide={currentSlide}
          sparkIcon={sparkIcon}
          key={`section_${index}`}
        />
      );
    }

    if (section.page.document.type === "contact") {
      return (
        <Contact
          data={section.page.document}
          currentSlide={currentSlide}
          sparkIcon={sparkIcon}
          key={`contact_section_${index}`}
        />
      );
    }

    if (section.page.document.type === "collaboration") {
      return (
        <Collaboration
          data={section.page.document}
          sectionColors={textColors}
          sparkIcon={sparkIcon}
          key={`collaboration_section_${index}`}
        />
      );
    }

    if (section.page.document.type === "jobs") {
      return (
        <Jobs
          data={section.page.document}
          sectionColors={textColors}
          content={content.animation_order}
          sparkIcon={sparkIcon}
          key={`jobs_section_${index}`}
        />
      );
    }

    if (section.page.document.type === "testimonials") {
      return (
        <Testimonials
          data={section.page.document}
          sectionColors={textColors}
          content={content.animation_order}
          sparkIcon={sparkIcon}
          key={`articles_section_${index}`}
        />
      );
    }

    if (section.page.document.type === "articles") {
      return (
        <Articles
          data={section.page.document}
          sectionColors={textColors}
          content={content.animation_order}
          sparkIcon={sparkIcon}
          key={`articles_section_${index}`}
        />
      );
    }
  });

  const sectionMenuFullPage = content.sections.map(section =>
    convertToKebabCase(section.page.document.data.menu_title.text)
  );

  sectionColors.unshift("#0a2a2f");
  textColors.unshift("#0a2a2f");
  sectionMenuFullPage.unshift("home");

  return (
    <ReactFullpage
      scrollOverflow={true}
      scrollOverflowReset={true}
      scrollingSpeed={700}
      continuousVertical={true}
      sectionsColor={sectionColors}
      anchors={sectionMenuFullPage}
      sectionSelector={`.section`}
      afterLoad={(origin, destination, direction) =>
        afterLoad(origin, destination, direction)
      }
      scrollOverflowOptions={{ preventDefault: false }}
      lazyLoading={false}
      verticalCentered={false}
      render={({ state, fullpageApi }) => {
        if (fullPageInstance === null || fullPageInstance === undefined) {
          setFullPageInstance(fullpageApi);
        }

        return (
          <ReactFullpage.Wrapper>
            <div className="section">
              <Div100vh>
                <IntroRow>
                  <div className="flex-container">
                    <div>
                      <AnimationContainer>
                        <SparkText>
                          <img
                            src={sparkIcon}
                            alt={`Spark Icon`}
                            visible={isSparkVisible}
                          />
                        </SparkText>

                        <AnimatedText visible={isAnimationVisible}>
                          <TextLoop delay={2400} adjustingSpeed={0}>
                            {animations}
                          </TextLoop>
                        </AnimatedText>
                      </AnimationContainer>

                      <AnimationTextContainer
                        opportunityVisible={isOpportunitiesTextVisible}
                      >
                        <div>
                          <h1>{content.subtitle}</h1>
                          <AnimationTextBox
                            visible={isIntroTextVisible}
                            dangerouslySetInnerHTML={{
                              __html: content.text.html,
                            }}
                          />

                          <SocialMediaLinks visible={isIntroTextVisible}>
                            <div className="container">{socialLinks}</div>
                          </SocialMediaLinks>
                        </div>
                      </AnimationTextContainer>
                    </div>
                  </div>
                </IntroRow>
              </Div100vh>
            </div>
            {sections}
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
};

export default FullpageWrapper;
