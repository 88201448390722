import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  height: 55px;

  @media (max-width: 500px) {
    height: 30px;
  }
`;

const TalentText = () => (
  <Icon
    id="talent-text"
    dataName="talent-text"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1256.87 240.28"
  >
    <g>
      <path
        d="M71,185V54.65H27.05V36.39H137.23V54.65H93.5V185Z"
        fill="#0a2a2f"
      />
      <path
        d="M165.68,187.55q-13.38,0-22.08-4.46a30.51,30.51,0,0,1-12.95-11.78,31.37,31.37,0,0,1-4.25-16q0-15.29,11.89-24.2t34-8.92h27.6v-1.91q0-12.31-6.69-18.47t-17.3-6.16a28.75,28.75,0,0,0-16.24,4.56q-6.9,4.57-8.39,13.48h-22.5A35.36,35.36,0,0,1,136.49,94a43.34,43.34,0,0,1,17-12.53,57.52,57.52,0,0,1,22.61-4.35q22.08,0,34.18,11.57t12.1,31.53V185H202.83l-1.91-18A40,40,0,0,1,188,181.6Q179.48,187.54,165.68,187.55Zm4.46-18.26q9.13,0,15.39-4.35a29.46,29.46,0,0,0,9.77-11.57,46.55,46.55,0,0,0,4.35-15.92H174.6q-13.37,0-19,4.67A14.57,14.57,0,0,0,150,153.79a13.51,13.51,0,0,0,5.41,11.36Q160.8,169.29,170.14,169.29Z"
        fill="#0a2a2f"
      />
      <path d="M238.07,185V32.15h22.5V185Z" fill="#0a2a2f" />
      <path
        d="M327.66,187.55q-15.5,0-27.49-6.9a49,49,0,0,1-18.79-19.21q-6.8-12.31-6.79-28.66,0-16.56,6.69-29.08A48.71,48.71,0,0,1,300,84.16q12-7,27.92-7,15.5,0,27,6.9a48.8,48.8,0,0,1,17.83,18.36A51.16,51.16,0,0,1,379,127.68q0,2.12-.11,4.56t-.32,5.41H296.66q1.06,15.28,10.08,23.25a30.64,30.64,0,0,0,20.91,8q9.55,0,16-4.35a26.36,26.36,0,0,0,9.66-11.78h22.5A47.8,47.8,0,0,1,359,177.57Q346.34,187.55,327.66,187.55Zm0-91.92a31.85,31.85,0,0,0-20,6.69Q299,109,297.09,122.37h59.44q-.64-12.31-8.71-19.53T327.66,95.62Z"
        fill="#0a2a2f"
      />
      <path
        d="M391.77,185V79.7h20l1.7,18.47a36.65,36.65,0,0,1,14.33-15.39,41.72,41.72,0,0,1,21.76-5.63q19.11,0,30,11.89t10.93,35.45V185H468.2V126.83q0-30.57-25.05-30.57a26.86,26.86,0,0,0-20.7,8.92q-8.17,8.92-8.17,25.48V185Z"
        fill="#0a2a2f"
      />
      <path
        d="M550.57,185q-15.5,0-24.62-7.54t-9.13-26.86v-52h-18V79.7h18L519.57,53h19.74V79.7H569V98.59H539.32v52q0,8.71,3.71,12t12.84,3.29H568V185Z"
        fill="#0a2a2f"
      />
    </g>
  </Icon>
);

export default TalentText;
