import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  height: 55px;

  @media (max-width: 500px) {
    height: 30px;
  }
`;

const ChangeText = () => (
  <Icon
    id="change-text"
    dataName="change-text"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1256.87 240.28"
  >
    <g>
      <path
        d="M102.42,187.55q-22.08,0-38.11-9.66a64.64,64.64,0,0,1-24.73-27q-8.71-17.3-8.7-40,0-22.93,8.7-40.23A65.38,65.38,0,0,1,64.31,43.61q16-9.76,38.11-9.77,26.11,0,42.67,12.74t21,35.67H141.05a35.3,35.3,0,0,0-12.74-20.59Q118.55,54,102.2,54,79.91,54,67,69.19T54,110.91q0,26.33,12.95,41.4t35.24,15.07q16.34,0,26.11-7.22a33.23,33.23,0,0,0,12.74-19.74h25.05q-4.46,22.08-21,34.6T102.42,187.55Z"
        fill="#0a2a2f"
      />
      <path
        d="M179.48,185V32.15H202V96.68a37.81,37.81,0,0,1,14.75-14.33,42.64,42.64,0,0,1,20.91-5.2q18.89,0,29.72,11.89t10.83,35.45V185H255.9V126.83q0-30.57-24.41-30.57a27.76,27.76,0,0,0-21.12,8.92Q202,114.09,202,130.65V185Z"
        fill="#0a2a2f"
      />
      <path
        d="M329.78,187.55q-13.37,0-22.08-4.46a30.52,30.52,0,0,1-13-11.78,31.37,31.37,0,0,1-4.24-16q0-15.29,11.89-24.2t34-8.92H364v-1.91q0-12.31-6.69-18.47T340,95.62a28.75,28.75,0,0,0-16.24,4.56q-6.9,4.57-8.38,13.48h-22.5A35.38,35.38,0,0,1,300.59,94a43.33,43.33,0,0,1,17-12.53,57.53,57.53,0,0,1,22.61-4.35q22.08,0,34.18,11.57t12.1,31.53V185H366.93L365,167a40,40,0,0,1-13,14.65Q343.58,187.54,329.78,187.55Zm4.46-18.26q9.13,0,15.39-4.35a29.43,29.43,0,0,0,9.77-11.57,46.46,46.46,0,0,0,4.35-15.92H338.7q-13.37,0-19,4.67a14.57,14.57,0,0,0-5.62,11.68,13.52,13.52,0,0,0,5.41,11.36Q324.9,169.29,334.24,169.29Z"
        fill="#0a2a2f"
      />
      <path
        d="M402.17,185V79.7h20l1.7,18.47a36.66,36.66,0,0,1,14.33-15.39,41.72,41.72,0,0,1,21.76-5.63Q479,77.15,490,89t10.93,35.45V185H478.6V126.83q0-30.57-25.05-30.57a26.86,26.86,0,0,0-20.7,8.92q-8.18,8.92-8.17,25.48V185Z"
        fill="#0a2a2f"
      />
      <path
        d="M559.91,152.73a58,58,0,0,1-15.29-1.91l-9.13,8.92a23.87,23.87,0,0,0,5.73,2.55,61.57,61.57,0,0,0,9.55,1.91q6.15.85,16.77,1.7,21,1.91,30.36,10.08t9.34,21.76a34.16,34.16,0,0,1-5,17.73q-5,8.38-15.5,13.59t-26.86,5.2q-22.08,0-35.77-8.39T510.44,200.5a27.18,27.18,0,0,1,3.5-13.06,38.7,38.7,0,0,1,11.14-12.21,46.48,46.48,0,0,1-7.75-4.14,50.45,50.45,0,0,1-5.84-4.56v-5.1l18.26-18.68q-12.32-10.61-12.31-27.81a37.16,37.16,0,0,1,5-19A35.81,35.81,0,0,1,537,82.25q9.55-5.1,22.93-5.1a52.07,52.07,0,0,1,16.56,2.55h39.27V94.56l-18.68,1.06a37.55,37.55,0,0,1,5.1,19.32,37,37,0,0,1-5,19.11,36.23,36.23,0,0,1-14.44,13.59Q573.28,152.73,559.91,152.73Zm-28.23,45.64q0,9.13,8.07,13.69t20.17,4.56q11.67,0,18.89-5T586,198.37a13.58,13.58,0,0,0-4.88-10.51q-4.89-4.35-18.47-5.41-10.19-.64-18-1.91-7.43,4-10.19,8.7A17.87,17.87,0,0,0,531.67,198.37Zm28.23-63.26q9.76,0,15.82-5.2t6.05-14.75q0-9.76-6.05-14.86T559.91,95.2q-10,0-16,5.09t-6.05,14.86q0,9.55,6.05,14.75T559.91,135.11Z"
        fill="#0a2a2f"
      />
      <path
        d="M670.3,187.55q-15.5,0-27.49-6.9A49,49,0,0,1,624,161.43q-6.8-12.31-6.79-28.66,0-16.56,6.69-29.08A48.71,48.71,0,0,1,642.6,84.16q12-7,27.92-7,15.5,0,27,6.9a48.8,48.8,0,0,1,17.83,18.36,51.16,51.16,0,0,1,6.37,25.26q0,2.12-.11,4.56t-.32,5.41H639.31q1.06,15.28,10.08,23.25a30.64,30.64,0,0,0,20.91,8q9.55,0,16-4.35A26.36,26.36,0,0,0,696,152.73h22.5a47.8,47.8,0,0,1-16.88,24.84Q689,187.55,670.3,187.55Zm0-91.92a31.85,31.85,0,0,0-20,6.69q-8.71,6.69-10.62,20.06h59.44q-.64-12.31-8.71-19.53T670.3,95.62Z"
        fill="#0a2a2f"
      />
    </g>
  </Icon>
);

export default ChangeText;
