import React, { useState } from "react";
import styled from "styled-components";
import Select from "react-select";

// Components
import ConversationText from "../icons/conversation-text";
import { ContactFormEmployer } from "../contact/contact-form-employer";
import { ContactFormEmployee } from "../contact/contact-form-employee";
import { GeneralEnquiry } from "../contact/general-enquiry";

const ContactSection = styled.div`
  margin: 0 0 30px 0;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;
  align-items: flex-start;

  padding: 0 0 30px 0;
`;

const ContactFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: calc(100% - 400px - 60px - 60px);
  padding: 0 30px;
  margin: 37px 0 0 0;

  @media (max-width: 920px) {
    width: calc(100% - 300px - 60px);
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  @media (max-width: 770px) {
    width: calc(100% - 280px - 60px - 60px);
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;

    align-items: flex-start;
  }

  @media (max-width: 600px) {
    margin: 30px 0;
  }
`;

const TextBox = styled.div`
  width: 100%;
  max-width: 400px;
  color: ${props => (props.color ? props.color : "#fff")};

  @media (max-width: 920px) {
    max-width: 300px;
  }

  @media (max-width: 770px) {
    max-width: 280px;
  }

  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-self: flex-start;

  padding: 30px;

  width: 100%;

  & .subheading {
    width: 100%;

    & h3 {
      font-size: 19px;
      font-weight: normal;

      margin: 20px 0 0 0;
    }
  }

  & img {
    height: 55px;
    width: auto;

    @media (max-width: 500px) {
      height: 30px;
    }
  }

  @media (max-width: 767px) {
    padding: 20px;
  }
`;

const Container = styled.div`
  z-index: 1;

  width: 100%;
  max-width: 400px;
  color: ${props => props.color};
  padding: 0 30px;

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  & .subheading {
    width: 100%;

    & h3 {
      font-size: 30px;
      font-weight: 700;

      margin: 50px 0 0 0;

      color: ${props => props.color};

      @media (max-width: 900px) {
        font-size: 26px;
      }

      @media (max-width: 500px) {
        font-size: 20px;
      }
    }
  }
`;

const EnquireContainer = styled.div`
  position: relative;
  display: block;
  z-index: 10;

  margin: 1em 0 0 0;

  & .contact-form-option__control {
    border-color: inherit;
    border-bottom: 1px solid #0a2a2f;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;

    position: relative;
    z-index: 100;

    &:hover {
      border-color: inherit;
    }
  }

  & .contact-form-option__single-value {
    color: inherit;
  }

  & .contact-form-option__single-value {
    margin-left: 0;
  }

  & .contact-form-option__value-container {
    padding: 5px 10px;
  }

  & .css-tlfecz-indicatorContainer {
    color: inherit;
    padding: 0;
  }

  & .contact-form-option__indicator {
    padding: 5px 10px;
  }

  & .contact-form-option__placeholder {
    color: inherit;
    padding-left: 0;

    margin-left: 0;
    margin-right: 0;
  }

  & .contact-form-option__indicator-separator {
    display: none;
  }

  & .contact-form-option__menu {
    border-radius: 0;

    & .contact-form-option__option--is-focused {
      background-color: #f5f5f5;
    }

    & .contact-form-option__option--is-selected {
      background-color: #0a2a2f;
      color: #fff;
    }
  }

  & .contact-form-option__menu-list {
    padding: 0;
  }

  & .css-b8ldur-Input {
    margin: 0;
  }
`;

const Credits = styled.div`
  max-width: 400px;

  padding: 0 30px;
  margin: 1em 0 30px 0;

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  & p {
    font-size: 14px;
    line-height: 18px;

    margin: 0;

    & a {
      text-decoration: underline;
    }
  }
`;

export const Contact = ({ data, currentSlide, title, id, sparkIcon }) => {
  const [selectedForm, setSelectedForm] = useState("general-enquiry");

  const options = [
    { value: "general-enquiry", label: "General Enquiry" },
    { value: "employer", label: "Employer" },
    { value: "employee", label: "Employee" },
  ];

  return (
    <ContactSection
      key={data.prismicId}
      className="section fp-auto-height-responsive"
    >
      <div>
        <SectionTitle color={"#0a2a2f"}>
          <img src={sparkIcon} alt={`Spark Icon`} />
          <ConversationText />
        </SectionTitle>

        <Flex>
          <Container>
            <div
              className="subheading"
              color={"#0a2a2f"}
              dangerouslySetInnerHTML={{
                __html: data.data.subheading.html,
              }}
            />
            <TextBox
              color={"#0a2a2f"}
              dangerouslySetInnerHTML={{
                __html: data.data.text.html,
              }}
            />

            <EnquireContainer>
              <Select
                options={options}
                defaultValue={options[0]}
                placeholder={options[0].label}
                classNamePrefix="contact-form-option"
                onChange={event => setSelectedForm(event.value)}
                name="enquire"
                isSearchable={false}
              />
            </EnquireContainer>
          </Container>

          <ContactFormContainer>
            <ContactFormEmployer
              gdprText={data.data.gdpr_text}
              display={selectedForm === `employer` ? `block` : `none`}
            />

            <ContactFormEmployee
              gdprText={data.data.gdpr_text}
              display={selectedForm === `employee` ? `block` : `none`}
            />

            <GeneralEnquiry
              gdprText={data.data.gdpr_text}
              display={selectedForm === `general-enquiry` ? `block` : `none`}
            />
          </ContactFormContainer>
        </Flex>

        <Credits>
          <p>Website design by Gareth Rutter, Bellow</p>
          <p>Development by Kieran Startup</p>
        </Credits>
      </div>
    </ContactSection>
  );
};
