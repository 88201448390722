import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  height: 55px;

  @media (max-width: 500px) {
    height: 30px;
  }
`;

const ConversationText = () => (
  <Icon
    id="conversation-text"
    dataName="conversation-text"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1256.87 240.28"
  >
    <g>
      <path
        d="M102.42,187.55q-22.08,0-38.11-9.66a64.64,64.64,0,0,1-24.73-27q-8.71-17.3-8.7-40,0-22.93,8.7-40.23A65.37,65.37,0,0,1,64.31,43.61q16-9.76,38.11-9.77,26.11,0,42.67,12.74t21,35.67H141.05a35.3,35.3,0,0,0-12.74-20.59Q118.55,54,102.2,54,79.91,54,67,69.19T54,110.91q0,26.33,12.95,41.4t35.24,15.07q16.34,0,26.11-7.22a33.22,33.22,0,0,0,12.74-19.74H166.1q-4.46,22.08-21,34.6T102.42,187.55Z"
        fill="#0a2a2f"
      />
      <path
        d="M228.73,187.55a53.31,53.31,0,0,1-27.07-6.9,50.34,50.34,0,0,1-19-19.32q-7-12.42-7-29t7.11-29A50.89,50.89,0,0,1,202,84.05a56.34,56.34,0,0,1,54,0,51.16,51.16,0,0,1,19.11,19.32q7.11,12.42,7.11,29t-7.11,29a50.89,50.89,0,0,1-19.21,19.32A53.91,53.91,0,0,1,228.73,187.55Zm0-19.32a29.13,29.13,0,0,0,15-4,29.59,29.59,0,0,0,11.15-12q4.24-8,4.25-19.85t-4.14-19.85a29.32,29.32,0,0,0-41-12,29.58,29.58,0,0,0-11.15,12q-4.25,8-4.25,19.85t4.25,19.85a29.8,29.8,0,0,0,11,12A28.56,28.56,0,0,0,228.73,168.23Z"
        fill="#0a2a2f"
      />
      <path
        d="M296,185V79.7h20l1.7,18.47A36.66,36.66,0,0,1,332,82.78a41.72,41.72,0,0,1,21.76-5.63q19.11,0,30,11.89t10.93,35.45V185H372.45V126.83q0-30.57-25.05-30.57a26.86,26.86,0,0,0-20.7,8.92q-8.18,8.92-8.17,25.48V185Z"
        fill="#0a2a2f"
      />
      <path
        d="M438.48,185,399,79.7h23.56l29.3,83.86,29.3-83.86H504.5L465.23,185Z"
        fill="#0a2a2f"
      />
      <path
        d="M556.3,187.55q-15.5,0-27.49-6.9A49,49,0,0,1,510,161.43q-6.8-12.31-6.79-28.66,0-16.56,6.69-29.08a48.7,48.7,0,0,1,18.68-19.53q12-7,27.92-7,15.5,0,27,6.9a48.8,48.8,0,0,1,17.83,18.36,51.15,51.15,0,0,1,6.37,25.26q0,2.12-.11,4.56t-.32,5.41H525.3q1.06,15.28,10.08,23.25a30.64,30.64,0,0,0,20.91,8q9.55,0,16-4.35A26.36,26.36,0,0,0,582,152.73h22.5a47.8,47.8,0,0,1-16.88,24.84Q575,187.55,556.3,187.55Zm0-91.92a31.86,31.86,0,0,0-20,6.69q-8.71,6.69-10.61,20.06h59.44q-.64-12.31-8.7-19.53T556.3,95.62Z"
        fill="#0a2a2f"
      />
      <path
        d="M620.41,185V79.7h20.17l1.91,20A42,42,0,0,1,657.88,83.2q9.87-6,23.88-6.05v23.56h-6.16a42.7,42.7,0,0,0-16.67,3.08,24.39,24.39,0,0,0-11.68,10.4q-4.35,7.32-4.35,20.27V185Z"
        fill="#0a2a2f"
      />
      <path
        d="M732.5,187.55q-20,0-32.91-9.77a36.56,36.56,0,0,1-14.86-25.9h22.71a22.46,22.46,0,0,0,8.07,12.42q6.37,5.2,16.77,5.2,10.19,0,14.86-4.25a12.88,12.88,0,0,0,4.67-9.77q0-8.07-6.47-10.93a90.9,90.9,0,0,0-17.94-5.2,135.74,135.74,0,0,1-17.73-5.1,39.14,39.14,0,0,1-14.65-9q-5.84-5.84-5.84-15.82a29.28,29.28,0,0,1,10.61-23q10.61-9.24,29.72-9.23,17.62,0,28.55,8.49t12.84,24H749.27A15.61,15.61,0,0,0,743,99q-5.2-3.82-13.91-3.82-8.49,0-13.16,3.5a10.8,10.8,0,0,0-4.67,9q0,5.52,6.48,8.7a83.91,83.91,0,0,0,17.09,5.73,183.15,183.15,0,0,1,19.64,5.41,34.73,34.73,0,0,1,14.54,9q5.52,5.95,5.52,17.41.21,14.44-11.15,24T732.5,187.55Z"
        fill="#0a2a2f"
      />
      <path
        d="M824.21,187.55q-13.38,0-22.08-4.46a30.52,30.52,0,0,1-13-11.78,31.37,31.37,0,0,1-4.25-16q0-15.29,11.89-24.2t34-8.92h27.6v-1.91q0-12.31-6.69-18.47t-17.3-6.16a28.75,28.75,0,0,0-16.24,4.56q-6.9,4.57-8.39,13.48h-22.5A35.37,35.37,0,0,1,795,94,43.33,43.33,0,0,1,812,81.5a57.53,57.53,0,0,1,22.61-4.35q22.08,0,34.18,11.57t12.1,31.53V185H861.36l-1.91-18a40,40,0,0,1-13,14.65Q838,187.54,824.21,187.55Zm4.46-18.26q9.13,0,15.39-4.35a29.44,29.44,0,0,0,9.77-11.57,46.51,46.51,0,0,0,4.35-15.92H833.13q-13.37,0-19,4.67a14.57,14.57,0,0,0-5.63,11.68,13.52,13.52,0,0,0,5.41,11.36Q819.33,169.29,828.67,169.29Z"
        fill="#0a2a2f"
      />
      <path
        d="M938.64,185q-15.5,0-24.63-7.54t-9.13-26.86v-52h-18V79.7h18L907.64,53h19.74V79.7h29.72V98.59H927.39v52q0,8.71,3.71,12t12.84,3.29H956V185Z"
        fill="#0a2a2f"
      />
      <path
        d="M982.37,59.74a14.65,14.65,0,0,1-10.51-3.93,13,13,0,0,1-4.14-9.87,13,13,0,0,1,4.14-9.87,16,16,0,0,1,21,0A13,13,0,0,1,997,45.95a13,13,0,0,1-4.14,9.87A14.65,14.65,0,0,1,982.37,59.74ZM971.12,185V79.7h22.5V185Z"
        fill="#0a2a2f"
      />
      <path
        d="M1062,187.55a53.31,53.31,0,0,1-27.07-6.9,50.34,50.34,0,0,1-19-19.32q-7-12.42-7-29t7.11-29a50.89,50.89,0,0,1,19.21-19.32,56.34,56.34,0,0,1,54,0,51.16,51.16,0,0,1,19.11,19.32q7.11,12.42,7.11,29t-7.11,29a50.89,50.89,0,0,1-19.21,19.32A53.91,53.91,0,0,1,1062,187.55Zm0-19.32a29.13,29.13,0,0,0,15-4,29.59,29.59,0,0,0,11.15-12q4.24-8,4.25-19.85t-4.14-19.85a29.32,29.32,0,0,0-41-12,29.58,29.58,0,0,0-11.15,12q-4.25,8-4.25,19.85t4.25,19.85a29.8,29.8,0,0,0,11,12A28.56,28.56,0,0,0,1062,168.23Z"
        fill="#0a2a2f"
      />
      <path
        d="M1129.28,185V79.7h20l1.7,18.47a36.66,36.66,0,0,1,14.33-15.39A41.72,41.72,0,0,1,1187,77.15q19.11,0,30,11.89T1228,124.49V185H1205.7V126.83q0-30.57-25.05-30.57a26.86,26.86,0,0,0-20.7,8.92q-8.18,8.92-8.17,25.48V185Z"
        fill="#0a2a2f"
      />
    </g>
  </Icon>
);

export default ConversationText;
