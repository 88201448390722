import React from "react";
import styled from "styled-components";

// Components
import Advice from "../icons/advice";
import Brilliance from "../icons/brilliance";
import Change from "../icons/change";
import Conversation from "../icons/conversation";
import Talent from "../icons/talent";
import BrillianceText from "../icons/brilliance-text";
import TalentText from "../icons/talent-text";
import ChangeText from "../icons/change-text";
import AdviceText from "../icons/advice-text";

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;
  align-items: flex-start;

  padding: 0 0 30px 0;
`;

const FlexIcon = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  height: calc(100vh - 240px);
  width: calc(100% - 400px - 60px - 30px);

  padding: 0 30px 0 0;

  & > svg {
    max-height: calc(100vh - 240px);
    overflow: visible !important;
  }

  @media (max-width: 920px) {
    width: calc(100% - 300px - 60px);
  }

  @media (max-width: 770px) {
    width: calc(100% - 280px - 60px - 60px);
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    padding: 0;

    align-items: flex-start;
  }

  @media (max-width: 600px) {
    margin: 30px 0;
  }
`;

const TextBox = styled.div`
  width: 100%;
  max-width: 400px;
  color: ${props => (props.color ? props.color : "#fff")};

  @media (max-width: 920px) {
    max-width: 300px;
  }

  @media (max-width: 770px) {
    max-width: 280px;
  }

  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-self: flex-start;

  padding: 30px;

  width: 100%;

  & img {
    height: 55px;
    width: auto;

    @media (max-width: 500px) {
      height: 30px;
    }
  }

  @media (max-width: 767px) {
    padding: 20px;
  }
`;

const Container = styled.div`
  z-index: 1;

  & .subheading {
    width: 100%;

    & h3 {
      font-size: 30px;
      font-weight: 700;

      margin: 50px 0 0 0;

      color: ${props => props.color};

      @media (max-width: 900px) {
        font-size: 26px;
      }

      @media (max-width: 500px) {
        font-size: 20px;
      }
    }
  }

  &:nth-child(1) {
    padding: 0 30px;
    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }
`;

export const Section = ({ data, currentSlide, title, id, sparkIcon }) => (
  <div key={data.prismicId} className="section fp-auto-height-responsive">
    <div>
      <SectionTitle color={title === "Brilliance" ? `#fff` : `#0a2a2f`}>
        <img src={sparkIcon} alt={`Spark Icon`} />
        {title === "Brilliance" && <BrillianceText />}
        {title === "Talent" && <TalentText />}
        {title === "Change" && <ChangeText />}
        {title === "Advice" && <AdviceText />}
      </SectionTitle>

      <Flex>
        <Container
          color={
            title === "Brilliance" || title === "Advice" ? `#fff` : `#0a2a2f`
          }
        >
          <div
            className="subheading"
            dangerouslySetInnerHTML={{
              __html: data.data.subheading.html,
            }}
          />
          <TextBox
            color={
              title === "Brilliance" || title === "Advice" ? `#fff` : `#0a2a2f`
            }
            dangerouslySetInnerHTML={{
              __html: data.data.text.html,
            }}
          />
        </Container>

        <FlexIcon>
          {title === "Brilliance" && (
            <Brilliance activeSlide={currentSlide === 1} />
          )}
          {title === "Talent" && <Talent activeSlide={currentSlide === 4} />}
          {title === "Change" && <Change activeSlide={currentSlide === 5} />}
          {title === "Advice" && <Advice activeSlide={currentSlide === 6} />}
          {title === "Conversation" && (
            <Conversation activeSlide={currentSlide === 7} />
          )}
        </FlexIcon>
      </Flex>
    </div>
  </div>
);
