import React from "react";
import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;
  align-items: flex-start;

  padding: 0 0 30px 0;
`;

const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: flex-start;

  padding: 30px;

  color: ${props => (props.color ? props.color : "#fff")};

  width: 100%;

  & .subheading {
    width: 100%;

    & h3 {
      font-size: 19px;
      font-weight: normal;

      margin: 20px 0 0 0;
    }
  }

  & img {
    height: 55px;
    width: auto;

    @media (max-width: 500px) {
      height: 30px;
    }
  }

  @media (max-width: 767px) {
    padding: 20px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 400px;
  color: ${props => (props.color ? props.color : "#fff")};
  padding: 0 30px;

  padding-top: 0;

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  @media (max-width: 500px) {
    max-width: 100%;
  }

  & .subheading {
    width: 100%;

    & h3 {
      font-size: 30px;
      font-weight: 700;

      margin: 50px 0 0 0;

      color: ${props => props.color};

      @media (max-width: 900px) {
        font-size: 26px;
      }

      @media (max-width: 500px) {
        font-size: 20px;
      }
    }
  }
`;

const TextBox = styled.div`
  width: 100%;
  color: ${props => (props.color ? props.color : "#fff")};
`;

const CollaborationTextBox = styled.div`
  padding: 0 30px;

  column-count: 3;
  column-gap: 30px;

  @media (max-width: 960px) {
    column-count: 2;
  }

  @media (max-width: 767px) {
    column-count: 1;
    padding: 0 20px;
  }
`;

export const Collaboration = ({ data, sectionColors, sparkIcon }) => {
  const CollaborationContainer = styled.div`
    padding: 30px 0;
    width: 100%;

    & p {
      margin: 0;
      font-weight: 500;
      font-size: 30px;
      line-height: 1.25;

      @media (max-width: 900px) {
        font-size: 30px;
      }

      @media (max-width: 500px) {
        font-size: 26px;
      }
    }
    & p:nth-child(5n + 1) {
      color: ${sectionColors[5]};
    }
    & p:nth-child(5n + 2) {
      color: ${sectionColors[3]};
    }
    & p:nth-child(5n + 3) {
      color: ${sectionColors[6]};
    }
    & p:nth-child(5n + 4) {
      color: ${sectionColors[4]};
    }
    & p:nth-child(5n + 5) {
      color: ${sectionColors[1]};
    }
  `;

  return (
    <div key={data.prismicId} className="section fp-auto-height-responsive">
      <div>
        <SectionTitle color={"#fff"}>
          <img src={sparkIcon} alt={`Spark Icon`} />
          <img src={data.data.svg_text.url} alt={`Spark Icon`} />
        </SectionTitle>

        <Flex>
          <ContentContainer color={"#fff"}>
            <div
              className="subheading"
              dangerouslySetInnerHTML={{
                __html: data.data.subheading.html,
              }}
            />
            <TextBox
              color={"#fff"}
              dangerouslySetInnerHTML={{
                __html: data.data.text.html,
              }}
            />
          </ContentContainer>
          <CollaborationContainer>
            <CollaborationTextBox
              color={"#fff"}
              dangerouslySetInnerHTML={{
                __html: data.data.additional_text.html,
              }}
            />
          </CollaborationContainer>
        </Flex>
      </div>
    </div>
  );
};
