import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  height: 55px;

  @media (max-width: 500px) {
    height: 30px;
  }
`;

const BrillianceText = () => (
  <Icon
    id="brilliance-text"
    dataName="brilliance-text"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1256.87 240.28"
  >
    <g>
      <path
        d="M36.18,185V36.39h56q23.14,0,35,10.83a35.21,35.21,0,0,1,11.89,27.17q0,14.23-7.64,22.61A38.19,38.19,0,0,1,113,108.36a36.56,36.56,0,0,1,15.18,6.79,36.06,36.06,0,0,1,10.51,12.74,36.52,36.52,0,0,1,3.82,16.56,39,39,0,0,1-5.62,20.7,38.5,38.5,0,0,1-16.45,14.54Q109.64,185,93.92,185Zm22.5-84.92H89.47q12.95,0,19.74-6.05T116,77.37q0-10.4-6.69-16.56T89,54.65H58.68Zm0,66.45H91q13.58,0,21.12-6.37t7.54-17.83q0-11.46-7.85-18.15t-21.23-6.69H58.68Z"
        fill="#0a2a2f"
      />
      <path
        d="M155.7,185V79.7h20.17l1.91,20A42,42,0,0,1,193.17,83.2q9.87-6,23.88-6.05v23.56H210.9a42.7,42.7,0,0,0-16.66,3.08,24.39,24.39,0,0,0-11.68,10.4q-4.35,7.32-4.35,20.27V185Z"
        fill="#0a2a2f"
      />
      <path
        d="M238.49,59.74A14.65,14.65,0,0,1,228,55.82a13,13,0,0,1-4.14-9.87A13,13,0,0,1,228,36.07a16,16,0,0,1,21,0,13,13,0,0,1,4.14,9.87A13,13,0,0,1,249,55.82,14.65,14.65,0,0,1,238.49,59.74ZM227.24,185V79.7h22.5V185Z"
        fill="#0a2a2f"
      />
      <path d="M268.85,185V32.15h22.5V185Z" fill="#0a2a2f" />
      <path d="M309.18,185V32.15h22.5V185Z" fill="#0a2a2f" />
      <path
        d="M362.26,59.74a14.65,14.65,0,0,1-10.51-3.93,13,13,0,0,1-4.14-9.87,13,13,0,0,1,4.14-9.87,16,16,0,0,1,21,0,13,13,0,0,1,4.14,9.87,13,13,0,0,1-4.14,9.87A14.65,14.65,0,0,1,362.26,59.74ZM351,185V79.7h22.5V185Z"
        fill="#0a2a2f"
      />
      <path
        d="M428.28,187.55q-13.37,0-22.08-4.46a30.52,30.52,0,0,1-13-11.78,31.37,31.37,0,0,1-4.24-16q0-15.29,11.89-24.2t34-8.92h27.6v-1.91q0-12.31-6.69-18.47t-17.3-6.16a28.75,28.75,0,0,0-16.24,4.56q-6.9,4.57-8.38,13.48h-22.5A35.38,35.38,0,0,1,399.09,94a43.33,43.33,0,0,1,17-12.53,57.53,57.53,0,0,1,22.61-4.35q22.08,0,34.18,11.57T485,120.25V185H465.43l-1.91-18a40,40,0,0,1-13,14.65Q442.07,187.54,428.28,187.55Zm4.46-18.26q9.13,0,15.39-4.35a29.43,29.43,0,0,0,9.77-11.57,46.46,46.46,0,0,0,4.35-15.92H437.19q-13.37,0-19,4.67a14.57,14.57,0,0,0-5.62,11.68A13.52,13.52,0,0,0,418,165.15Q423.39,169.29,432.74,169.29Z"
        fill="#0a2a2f"
      />
      <path
        d="M500.67,185V79.7h20l1.7,18.47a36.65,36.65,0,0,1,14.33-15.39,41.72,41.72,0,0,1,21.76-5.63q19.11,0,30,11.89t10.93,35.45V185H577.09V126.83q0-30.57-25.05-30.57a26.86,26.86,0,0,0-20.7,8.92q-8.17,8.92-8.17,25.48V185Z"
        fill="#0a2a2f"
      />
      <path
        d="M665.2,187.55a54.64,54.64,0,0,1-27.71-7,49.89,49.89,0,0,1-19.11-19.43q-6.9-12.42-6.9-28.77t6.9-28.77a49.89,49.89,0,0,1,19.11-19.42,54.64,54.64,0,0,1,27.71-7q19.53,0,32.8,10.19t17.09,27.6H691.52a22.17,22.17,0,0,0-9.34-13.59,31.16,31.16,0,0,0-32.27-.74,30.07,30.07,0,0,0-11.25,12.1q-4.25,8-4.25,19.64T638.66,152a30.83,30.83,0,0,0,11.25,12.21,28.92,28.92,0,0,0,15.29,4.25,29.57,29.57,0,0,0,17-4.88,22.24,22.24,0,0,0,9.34-13.8h23.56a45.59,45.59,0,0,1-17,27.39Q684.73,187.55,665.2,187.55Z"
        fill="#0a2a2f"
      />
      <path
        d="M778.14,187.55q-15.5,0-27.49-6.9a49,49,0,0,1-18.79-19.21q-6.8-12.31-6.79-28.66,0-16.56,6.69-29.08a48.68,48.68,0,0,1,18.68-19.53q12-7,27.92-7,15.5,0,27,6.9a48.8,48.8,0,0,1,17.83,18.36,51.14,51.14,0,0,1,6.37,25.26q0,2.12-.11,4.56t-.32,5.41H747.14q1.06,15.28,10.08,23.25a30.64,30.64,0,0,0,20.91,8q9.55,0,16-4.35a26.35,26.35,0,0,0,9.66-11.78h22.5a47.79,47.79,0,0,1-16.88,24.84Q796.82,187.55,778.14,187.55Zm0-91.92a31.86,31.86,0,0,0-20,6.69q-8.71,6.69-10.61,20.06H807q-.64-12.31-8.7-19.53T778.14,95.62Z"
        fill="#0a2a2f"
      />
    </g>
  </Icon>
);

export default BrillianceText;
