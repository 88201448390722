import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";

// Components
import Header from "../components/global/header";
import FullpageWrapper from "../components/sections/full-page-wrapper";

const Index = ({ data }) => {
  const [isPageVisible, makePageVisible] = useState(false);
  const [isSparkVisible, makeSparkVisible] = useState(false);
  const [isAnimationVisible, makeAnimationVisible] = useState(false);
  const [isIntroTextVisible, makeIntroTextVisible] = useState(false);
  const [isOpportunitiesTextVisible, makeOpportunitiesTextVisible] = useState(
    false
  );
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fullPageInstance, setFullPageInstance] = useState(null);

  useEffect(() => {
    const pageTimer = setTimeout(() => {
      makePageVisible(true);
    }, 150);

    const sparkTimer = setTimeout(() => {
      makeSparkVisible(true);
    }, 1200);

    const opportunitiesTextTimer = setTimeout(() => {
      makeOpportunitiesTextVisible(true);
    }, 2200);

    const introTextTimer = setTimeout(() => {
      makeIntroTextVisible(true);
    }, 3200);

    const animationTimer = setTimeout(() => {
      makeAnimationVisible(true);
    }, 4200);

    return () =>
      clearTimeout(
        pageTimer,
        sparkTimer,
        animationTimer,
        opportunitiesTextTimer,
        introTextTimer
      );
  }, []);

  useEffect(() => {
    if (fullPageInstance !== null && fullPageInstance !== undefined) {
      const timer = setTimeout(() => {
        fullPageInstance.reBuild();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [fullPageInstance]);

  return (
    <>
      {/* {isPageVisible === true && ( */}
      {/* // <> */}
      <Header setCurrentSlide={setCurrentSlide} />
      <FullpageWrapper
        content={data.prismicHomepage.data}
        isSparkVisible={isSparkVisible}
        isAnimationVisible={isAnimationVisible}
        isOpportunitiesTextVisible={isOpportunitiesTextVisible}
        isIntroTextVisible={isIntroTextVisible}
        setCurrentSlide={setCurrentSlide}
        currentSlide={currentSlide}
        sparkIcon={data.prismicHomepage.data.svg_text.url}
        setFullPageInstance={setFullPageInstance}
        fullPageInstance={fullPageInstance}
      />
      {/* </> */}
      {/* // )} */}
      {/* </> */}
    </>
  );
};

export default Index;

export const HomepageQuery = graphql`
  query IndexQuery {
    prismicHomepage {
      data {
        subtitle
        text {
          html
        }
        svg_text {
          url
        }
        social_links_homepage {
          icon {
            url
          }
          link {
            url
          }
        }
        sections {
          page {
            document {
              ... on PrismicTestimonials {
                prismicId
                data {
                  title {
                    html
                    text
                  }
                  subheading {
                    html
                  }
                  text {
                    html
                  }
                  color
                  menu_color
                  menu_title {
                    text
                  }
                  svg_text {
                    url
                  }
                }
                type
              }
              ... on PrismicSection {
                prismicId
                data {
                  title {
                    html
                    text
                  }
                  subheading {
                    html
                  }
                  text {
                    html
                  }
                  color
                  menu_color
                  menu_title {
                    text
                  }
                  svg_text {
                    url
                  }
                }
                type
              }
              ... on PrismicContact {
                type
                data {
                  title {
                    html
                    text
                  }
                  subheading {
                    html
                  }
                  text {
                    html
                  }
                  gdpr_text {
                    html
                  }
                  menu_title {
                    text
                  }
                  color
                  menu_color
                  svg_text {
                    url
                  }
                }
                prismicId
              }
              ... on PrismicCollaboration {
                type
                data {
                  title {
                    html
                    text
                  }
                  subheading {
                    html
                  }
                  text {
                    html
                  }
                  menu_title {
                    text
                  }
                  color
                  menu_color
                  additional_text {
                    html
                  }
                  svg_text {
                    url
                  }
                }
                prismicId
              }
              ... on PrismicJobs {
                prismicId
                data {
                  text {
                    html
                  }
                  title {
                    html
                  }
                  subheading {
                    html
                  }
                  menu_title {
                    text
                  }
                  job_order {
                    jobs {
                      document {
                        ... on PrismicJobListing {
                          id
                          uid
                          prismicId
                          data {
                            job_title {
                              html
                            }
                            job_subheading
                            job_excerpt {
                              html
                            }
                            job_location
                          }
                        }
                      }
                    }
                  }
                  color
                  menu_color
                  svg_text {
                    url
                  }
                }
                type
              }
              ... on PrismicArticles {
                id
                type
                data {
                  color
                  menu_color
                  menu_title {
                    text
                  }
                  text {
                    html
                  }
                  title {
                    text
                  }
                  subheading {
                    html
                  }
                  svg_text {
                    url
                  }
                }
              }
            }
          }
        }
        animation_order {
          animation_text {
            document {
              ... on PrismicTestimonials {
                prismicId
                data {
                  title {
                    html
                    text
                  }
                  subheading {
                    html
                  }
                  text {
                    html
                  }
                  color
                  menu_color
                  menu_title {
                    text
                  }
                  svg_text {
                    url
                  }
                }
                type
              }
              ... on PrismicContact {
                type
                data {
                  title {
                    html
                    text
                  }
                  subheading {
                    html
                  }
                  text {
                    html
                  }
                  gdpr_text {
                    html
                  }
                  menu_title {
                    text
                  }
                  color
                  menu_color
                  svg_text {
                    url
                  }
                }
                prismicId
              }
              ... on PrismicSection {
                prismicId
                data {
                  title {
                    html
                    text
                  }
                  text {
                    html
                  }
                  color
                  menu_color
                  menu_title {
                    text
                  }
                  svg_text {
                    url
                  }
                }
                type
              }
              ... on PrismicCollaboration {
                type
                data {
                  title {
                    html
                    text
                  }
                  text {
                    html
                  }
                  menu_title {
                    text
                  }
                  color
                  menu_color
                  additional_text {
                    html
                  }
                  svg_text {
                    url
                  }
                }
                prismicId
              }
              ... on PrismicJobs {
                prismicId
                data {
                  text {
                    html
                  }
                  title {
                    html
                  }
                  menu_title {
                    text
                  }
                  job_order {
                    jobs {
                      document {
                        ... on PrismicJobListing {
                          id
                          uid
                          prismicId
                          data {
                            job_title {
                              html
                            }
                            job_subheading
                            job_excerpt {
                              html
                            }
                            job_location
                            short_copy_listing
                          }
                        }
                      }
                    }
                  }
                  color
                  menu_color
                  svg_text {
                    url
                  }
                }
                type
              }
            }
          }
        }
      }
    }
  }
`;
