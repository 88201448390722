import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Link } from "gatsby";

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;

  padding-bottom: 30px;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 400px;
  color: ${props => (props.color ? props.color : "#fff")};
  padding: 0 30px;

  padding-top: 0;

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  @media (max-width: 500px) {
    max-width: 100%;
  }

  & .subheading {
    width: 100%;

    & h3 {
      font-size: 30px;
      font-weight: 700;

      margin: 50px 0 0 0;

      color: ${props => props.color};

      @media (max-width: 900px) {
        font-size: 26px;
      }

      @media (max-width: 500px) {
        font-size: 20px;
      }
    }
  }
`;

const TextBox = styled.div`
  color: ${props => (props.color ? props.color : "#fff")};
`;

const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-self: flex-start;

  color: ${props => (props.color ? props.color : "#fff")};

  width: 100%;

  & .subheading {
    width: 100%;

    & h3 {
      font-size: 19px;
      font-weight: normal;

      margin: 20px 0 0 0;
    }
  }

  & img {
    height: 55px;
    width: auto;

    @media (max-width: 500px) {
      height: 30px;
    }
  }
`;

const Container = styled.div`
  width: ${props => props.width};

  padding: 30px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 20px;
  }
`;

const SingleArticle = styled.div`
  max-width: 780px;
  font-weight: 500;

  font-size: 15px;
  letter-spacing: 0.2px;

  & p:nth-child(2) {
    margin-top: 0;
  }

  & p:nth-child(4),
  & span {
    margin: 0;

    font-size: 14px;
    line-height: 18px;
  }

  & h2 {
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.2px;

    margin: 0;
  }
`;

const SingleArticleInner = styled.div`
  padding: 15px;

  & a {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: space-between;

    height: 100%;
    width: 100%;

    & div,
    & span {
      width: 100%;
    }

    & span {
      margin: 1em 0 0 0;
    }
  }

  @media (max-width: 600px) {
    & h1 {
      font-size: 20px;
    }
  }
`;

export const Articles = ({ data, sectionColors, content, sparkIcon }) => {
  const articlesData = useStaticQuery(graphql`
    {
      allPrismicArticle(sort: { fields: first_publication_date, order: DESC }) {
        edges {
          node {
            uid
            data {
              title {
                html
              }
              excerpt {
                html
              }
            }
          }
        }
      }
    }
  `);

  const ArticlesContainer = styled.div`
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-gap: 15px;
    // row-gap: 15px;

    width: calc(100% - 400px - 60px - 60px);
    padding: 30px;
    margin: 0 0 60px 0;

    @media (max-width: 767px) {
      padding: 0 20px;
    }

    // @media (max-width: 1200px) {
    //   grid-template-columns: 1fr 1fr;
    // }

    // @media (max-width: 1000px) {
    //   grid-template-columns: 1fr;
    // }

    @media (max-width: 800px) {
      // grid-template-columns: 1fr 1fr;
      width: 100%;
    }

    // @media (max-width: 600px) {
    //   grid-template-columns: 1fr;
    // }

    & > :nth-child(5n + 1) {
      background-color: ${sectionColors[6]};
    }
    & > :nth-child(5n + 2) {
      background-color: ${sectionColors[5]};
    }
    & > :nth-child(5n + 3) {
      background-color: ${sectionColors[3]};
    }
    & > :nth-child(5n + 4) {
      background-color: ${sectionColors[4]};
    }
    & > :nth-child(5n + 5) {
      background-color: ${sectionColors[1]};
    }
  `;

  const allArticles = articlesData.allPrismicArticle.edges.map(
    (article, index) => (
      <SingleArticle key={`single_article_${article.node.data.id}_${index}`}>
        <SingleArticleInner className="single-job-inner">
          <Link to={`/articles/${article.node.uid}`}>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: article.node.data.title.html,
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: article.node.data.excerpt.html,
                }}
              />
            </div>
            <br />
            <span>Read More →</span>
          </Link>
        </SingleArticleInner>
      </SingleArticle>
    )
  );

  return (
    <div key={data.prismicId} className="section fp-auto-height-responsive">
      <Container width={`100%`}>
        <SectionTitle color={"#fff"}>
          <img src={sparkIcon} alt={`Spark Icon`} />
          <img src={data.data.svg_text.url} alt={`Spark Icon`} />
        </SectionTitle>
      </Container>

      <Flex>
        <ContentContainer>
          <div
            className="subheading"
            dangerouslySetInnerHTML={{
              __html: data.data.subheading.html,
            }}
          />
          <TextBox
            color={"#fff"}
            dangerouslySetInnerHTML={{
              __html: data.data.text.html,
            }}
          />
        </ContentContainer>

        <ArticlesContainer>{allArticles}</ArticlesContainer>
      </Flex>
    </div>
  );
};
