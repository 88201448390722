import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 400px;
  color: #fff;
  padding: 0 30px;

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  & .subheading {
    width: 100%;

    & h3 {
      font-size: 30px;
      font-weight: 700;

      margin: 50px 0 0 0;

      color: ${props => props.color};

      @media (max-width: 900px) {
        font-size: 26px;
      }

      @media (max-width: 500px) {
        font-size: 20px;
      }
    }
  }
`;

const TextBox = styled.div`
  width: 100%;
  color: #fff;

  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-self: flex-start;

  padding: 30px 0;

  color: ${props => (props.color ? props.color : "#fff")};

  width: 100%;

  & .subheading {
    width: 100%;

    & h3 {
      font-size: 19px;
      font-weight: normal;

      margin: 20px 0 0 0;
    }
  }

  & img {
    height: 55px;
    width: auto;

    @media (max-width: 500px) {
      height: 30px;
    }
  }
`;

const Container = styled.div`
  width: ${props => props.width};

  padding: 0 30px;

  @media (max-width: 767px) {
    width: 100%;

    padding: 0 20px;
  }
`;

const SingleJob = styled.div`
  font-weight: 500;

  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.2px;

  & p:nth-child(2) {
    margin-top: 0;
  }

  & p:nth-child(4),
  & span {
    margin: 0;

    font-size: 14px;
    line-height: 18px;
  }

  & h1,
  & h2 {
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.2px;

    margin: 0;
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`;

export const Jobs = ({ data, sectionColors, content, sparkIcon }) => {
  const JobsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    row-gap: 15px;

    width: calc(100% - 400px - 60px - 60px);
    padding: 30px 30px 100px 30px;

    @media (max-width: 767px) {
      padding: 20px 20px 100px 20px;
    }

    @media (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }

    @media (max-width: 800px) {
      grid-template-columns: 1fr 1fr;
      width: 100%;
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    & > :nth-child(5n + 1) {
      background-color: ${sectionColors[1]};
    }
    & > :nth-child(5n + 2) {
      background-color: ${sectionColors[3]};
    }
    & > :nth-child(5n + 3) {
      background-color: ${sectionColors[4]};
    }
    & > :nth-child(5n + 4) {
      background-color: ${sectionColors[5]};
    }
    & > :nth-child(5n + 5) {
      background-color: ${sectionColors[6]};
    }
  `;

  const SingleJobInner = styled.div`
    padding: 15px;

    & a {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: space-between;

      height: 100%;
      width: 100%;

      & div,
      & span {
        width: 100%;
      }

      & span {
        margin: 1em 0 0 0;
      }
    }
  `;

  const jobListings = content
    .filter(animation => animation.animation_text.document.type === "jobs")
    .map(job => job.animation_text.document.data.job_order)
    .map(allJobs =>
      allJobs.map(job => (
        <SingleJob key={job.jobs.document.prismicId}>
          <SingleJobInner className="single-job-inner">
            <Link to={`/jobs/${job.jobs.document.uid}`}>
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: job.jobs.document.data.job_title.html,
                  }}
                />
                <p>{job.jobs.document.data.job_subheading}</p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: job.jobs.document.data.job_excerpt.html,
                  }}
                />
                <p>{job.jobs.document.data.job_location}</p>
              </div>
              <br />

              {job.jobs.document.data.short_copy_listing === false && (
                <span>Read More →</span>
              )}

              {/* {job.jobs.document.data.short_copy_listing === true ? (
                <span>
                  This opportunity is brand-new - more details to follow
                </span>
              ) : (
                <span>Read More →</span>
              )} */}
            </Link>
          </SingleJobInner>
        </SingleJob>
      ))
    );

  return (
    <div key={data.prismicId} className="section fp-auto-height-responsive">
      <Container width={`100%`}>
        <SectionTitle color={"#fff"}>
          <img src={sparkIcon} alt={`Spark Icon`} />
          <img src={data.data.svg_text.url} alt={`Spark Icon`} />
        </SectionTitle>
      </Container>

      <Flex>
        <ContentContainer>
          <div
            className="subheading"
            dangerouslySetInnerHTML={{
              __html: data.data.subheading.html,
            }}
          />
          <TextBox
            color={"#fff"}
            dangerouslySetInnerHTML={{
              __html: data.data.text.html,
            }}
          />
        </ContentContainer>
        <JobsContainer>{jobListings}</JobsContainer>
      </Flex>
    </div>
  );
};
